import React from "react";
import "./footer.css";

const Footer = () => { 
return ( 
	<body class="footerBody">
    <footer>
      <div class="info">
		<div class="copyright">Made By NitroBuilderGTR</div>
        <div class="legal">
          <a href="https://youtube.com/@nitrobuildergtr">YouTube</a>
		      <a href="https://instagram.com/nitrobuildergtr">Instagram</a>
        </div>        
      </div>
    </footer>
  </body>
); 
}; 
export default Footer; 
