import { Route, Routes, Link} from "react-router-dom"
  
// import Home component
import Home from "./components/home";
// import About component
import Downloads from "./components/downloads";
// import Patch Notes component
import PatchNotes from "./components/patch_notes_v4";
// import News component
import News from "./components/news";


// All News Articles
import News1 from "./components/news-articles/news_1";




// import Footer component
import Footer from './components/Footer';




function App() {
  return (
    <><Routes>
      <Route path="/" element={<Home />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/patch_Notes" element={<PatchNotes />} />
      <Route path="/news" element={<News />} />

      {`News Articles`}
      <Route path="/news1" element={<News1 />}/>
    </Routes><Footer /></>
  );
}

export default App;
