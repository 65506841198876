/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Routes, Link } from "react-router-dom";
import '../App.css';
import Home from "./home";
import Downloads from "./downloads";
import News from "./news";

import down_arrow from'../images/down_arrow.png';
import '../components/patch_notes-v4.css';

const PatchNotes = () => {
    return (
    <>
    <body class="page-top">
        <header class="header">

            <a class="logo" href="">GTR_Server</a>
            <input class="menu-btn" type="checkbox" id="menu-btn" />
            <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
                <ul class="menu">
                    <nav>
                        <li><Link class="link link-theme link-arrow" to="/">Home</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/downloads">Downloads</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/patch_Notes">Patch Notes</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/news">News</Link></li>
                        <li><a href="about.html" class="link link-theme link-arrow" id="mobile-center-title-second">Info / About</a></li>
                        <li><a href="" class="link link-theme link-arrow" id="mobile-center-title">Report a Bug in GTR_Server</a></li>
                    </nav>
                </ul>
            </header>

            <div>
                <div id="main" class="main">
                    <section class="intro">
                        <article class="container2" id="patch_notes_container_title">

                            <h1 id="underline">OPEN ALPHA</h1>
                            <h1>
                                GTR_Server <strong> - Patch Notes</strong>
                            </h1>
                            <div id="button_div_external">
                                <div id="button_div">
                                </div>
                            </div>
                            <h2 id="subtitle-home">
                                Version Release Date: To be determined.
                            </h2>

                        </article>
                    </section>

                    <div class="patch_notes_content_div">
                    <section class="work">
                        <article class="container2" id="one"> <h1>GTR_Server: Open Alpha v5 - Patch Notes</h1>
                            <a id="center-text"></a>
                            <p>
                                <a class="patch_notes_summary" id="center-text-bold">Notes:</a> Updated to Unreal Engine 5.2, New Building, Potential Performance Improvement
                            </p>
                            
                            <p class="changes_title" id="center-text-bold">Changes:</p>
                            <h4 class="changes-text"> - UNREAL ENGINE 5 🎉 I finally updated GTR_Server to Unreal Engine 5.2, all the way from engine verison 4.26.2 - Learn More Here: </h4>
                            <h4 class="changes-text"> - Updated Zeon_Launcher, to run the new version of GTR_Server (GTR_Server: Open Alpha v5)</h4>
                            <h4 class="changes-text"> - Added new building. The Cafe is a great place to hang out with freinds, so I added a nice Cafe.</h4>
                            <h4 class="changes-text">{` - Due to being updated to Unreal Engine 5.2, I had to use the newer versions of the cars. Enjoy the new Cars :)`}</h4>
                            <h4 class="changes-text"> - Potential performance improvement due to newer Unreal Engine Version (untested) - I'm not sure if it runs better, but I tried to optimise the performance. You can still change your performance settings if GTR_Server doesn't run well for you.</h4>
                            <h4 class="changes-text"> - Removed Player-Only and Server-Host-Only Cars. The current cars work with both the Client and Server (from what I have seen)</h4>
                            <h4 class="changes-text"> - Added a Control List / Keybinds List in the bottom right corner of the screen. It will show up when you enter a car.</h4>
                            <h4 class="changes-text"> - Re-Designed The Settings Page (not by a lot but still, it looks better than how it did in previous versions)</h4>

                            <p class="bugs_title" id="center-text-bold">Bugs</p>
                            <p></p>
                            <img src={down_arrow} id="down_arrow"></img>
                            </article>
                        {/* If you are reading this, thanks for checking the code :) */}
                        <article class="container" id="two">
                            <a id="underline-bold-text">Major Bugs</a>
                            <p></p>
                            <a id="">{`1) The "Admin Control Panel" doesn't work. This feature isn't finished yet.`}</a>
                            <h3>Solution: Just don't try to use it -  it doesn't work lol</h3>

                            <a id="">{`2) Emergency Respawn Button doesn't work `}</a>
                            <h3>Solution: The button is (as of writing this) not functional. If you need to respawn press H. If it doesnt work, please restart your application. </h3>

                            <a id="">{`3) Stuttering in non-server owner's car `}</a>
                            <h3>Solution: This is not going to be a easy fix (might take a extremely long time to fix, and there is no guarantee that this gets fixed). So you could stay on the old version (if you have it downloaded), or just wait until it's fixed. The suspected reason on why it stutters is the multiplayer replication being a bit weird for the player cars.</h3>

                            <a id="">{`4) FPS / Performance Lag `}</a>
                            <h3>Solution: Go to The In - Game Settings. You can then turn shadows to the lowest setting. It may look worse, but it gives more FPS. This is recommended for Lower - End PCs / Devices. </h3>
                        </article>

                        <article class="container" id="three">
                            <a id="underline-bold-text">Bug Fixes (Insane progress)</a>
                            <p></p>
                            <a id="">{`1) Fixed when running into car, and getting despawned`}</a>
                            <h3>Solution: The issue was something to do with the server (person who hosted the server) having more "access" or having "priority" to the car, compareed to a player who isn't classed as the server (someone who joined the server instead). This was an easy fix. Now there are 2 types of cars - some for the server owner (The Admin Car), and some for the players (The Player Car).</h3>

                            <a id="">{`2) Chairs being buggy`}</a>
                            <h3>Solution: Chairs were quite easy to fix - turned out the replication isn't easy to do lol</h3>

                            <a id="">{`3) The Performance is not great near loads of items and/or vehicles`}</a>
                            <h3>Solution: I have been trying to find a optimisation solution for this. It turned out there was a "null" AI controller that possessed the cars. This was potentially one of the reasons why the performance would be tanked everytime you load in. The performance issue still isn't fixed, but I think the issue has been temporarily solved. The performance still isn't great, but I think it has been made better by around 1% to 10% after removing the AI Controllers from the vehicles.</h3>
                        </article>

                    </section>
                    </div>
                </div>
            </div>
        </body>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js'></script><script src="./script.js"></script>
    </>);
};

export default PatchNotes;