/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Routes, Link } from "react-router-dom";
import '../App.css';
import Home from "./home"
import zeon_launcher from '../images/Zeon_Launcher-Img.png';
import gtr_server_image from '../images/GTR_Server-Img.png';
import '../components/downloadFooter.css';

const Downloads = () => {
return (
	<body class="page-top">

    <header class="header">

      <a class="logo" href="">GTR_Server</a>
      <input class="menu-btn" type="checkbox" id="menu-btn" />
      <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">
        <nav>
          <li><Link class="link link-theme link-arrow" to="/">Home</Link></li>
          <li><Link class="link link-theme link-arrow" to="/downloads">Downloads</Link></li>
          <li><Link class="link link-theme link-arrow" to="/patch_Notes">Patch Notes</Link></li>
          <li><Link class="link link-theme link-arrow" to="/news">News</Link></li>
          <li><a href="about.html" class="link link-theme link-arrow" id="mobile-center-title-second">Info / About</a></li>
          <li><a href="" class="link link-theme link-arrow" id="mobile-center-title">Report a Bug in GTR_Server</a></li>
        </nav>
      </ul>
    </header>

    <div>

      <div id="main" class="main">
        <section class="intro">
          <article class="container">
            <h1>
              GTR_Server - Open Alpha v5 <strong> - Avaliable Now</strong>
            </h1>
            <h2 id="subtitle-home">
              The Social Media Based Game.
            </h2>
          </article>

          <div class="column">
            <h2 id="LauncherTitle">
              Zeon_Launcher v1
            </h2>
            <h3 id="subtitle_launcher">Includes Game (GTR_Server)</h3>
            <img src={zeon_launcher} id="zeon-launcher-img" />
            <a href='https://zeondownloads.blob.core.windows.net/downloads/zeon_launcher_v1.zip'><button class="launcher_download_btn" role="button" >Download GTR_Server for Windows 10 or 11</button></a>
          </div>
        </section>
      </div>
    </div>
  </body>
);
};

export default Downloads;
