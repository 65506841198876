/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Routes, Link } from "react-router-dom";

import '../././.././components/patch_notes-v4.css';

import '../../components/news.css';

const News1 = () => {
    return (
    <>
    <body class="page-top">
        <header class="header">

            <a class="logo" href="">GTR_Server</a>
            <input class="menu-btn" type="checkbox" id="menu-btn" />
            <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
                <ul class="menu">
                    <nav>
                        <li><Link class="link link-theme link-arrow" to="/">Home</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/downloads">Downloads</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/patch_Notes">Patch Notes</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/news">News</Link></li>
                        <li><a href="about.html" class="link link-theme link-arrow" id="mobile-center-title-second">Info / About</a></li>
                        <li><a href="" class="link link-theme link-arrow" id="mobile-center-title">Report a Bug in GTR_Server</a></li>
                    </nav>
                </ul>
            </header>

            <div>
                <div id="main" class="main">
                    <section class="intro">
                        <article class="container2" id="patch_notes_container_title">

                            <h1 id="underline">News</h1>
                            <h1>
                                Uploaded by: <strong> NitroBuilderGTR</strong>
                            </h1>
                            <h2 id="subtitle-home">
                                Written: Wednesday 11th October 2023
                            </h2>

                        </article>
                    </section>
                    
                </div>

                <div class="news-content-box">
                        <a class="news-content-title">The Future for GTR_Server</a>
                        <a class="news-content-text">
                            First, I want to mention that I have noticed the support over the past 2 (almost 3🎉) years of this game's development.
                            I can not even begin to thank you all for the supportive comments I have been reciving from freinds and family about this project.
                        </a>
                        <a class="news-content-subtitle">
                            Now, I know GTR_Server has had a huge lack of updates for a while now. I have tried making ideas, and I have also tried implementing them.
                            I am not sure where this game is headed, or what the future for the game is, at the time of me writing this.
                            <p></p>
                            There will be a new version (Open Alpha v5) released by the end of this year, which includes a small change, in a large (almost) 1 year timespan,
                            of no updates. I feel like for v5 I should make a huge update, as it is a milestone update - <a class="news-content-text-bold">It is the 5th version of The Open Alpha. </a>
                            I am going to try to add something to the game before Open Beta v5 is released to the public.
                        </a>
                        <a class="news-content-title">Why have there been a lack of updates?</a>
                        <a class="news-content-text">
                            The development process is reasonably difficult, as I am using GTR_Server as a tool to showcase my ability in Multiplayer Game Development.
                            This means that I am learning, and learning can take time, especially with Game Development. I started learning Game Development back in early 2020,
                            and I have learned so much in almost 4 years (time is going too fast 😂).
                            I have had so much fun learning Game Development, and I don't plan to stop my Game Dev route anytime soon. However, I have been distracted with
                            watching the gaming space over the past few years, and it has evolved into this thing that I can only describe as a hell hole.
                            <p></p>
                            Unfinished games being released as "fully finished games" (for example, Battlefield 2042 and Cyberpunk 2077 at release), constant bugs,
                            and terrible performance, even on the highest spec Gaming PCs. There is such a small amount of games that are being released at decent quality now.
                            A recent game that released at decent quality is Counter Strike 2 (or CS:2 / CS:GO's sequel).
                            I have not been paid to say that, I just genuinely believe that that game is a genuinely decent game.
                            <p></p>
                            This trend of making games unfinished and releasing them as what is advertised as full-games is just stupid. I understand that game
                            studios will create new games, and maybe they will release before another game studio's game, but people would prefer to play
                            finished games / polished games than unfinsihed ones. It took Cyberpunk 2077 ages to get their reputation back, after their horrendous
                            launch in 2020.
                            <p></p>
                            <a class="news-content-text-bold">Anyway I feel like I am getting side-tracked...</a>
                            <p></p>
                            The reason why there has been a lack of updates is because I haven't been able to find new ideas for GTR_Server. The original plan was
                            to have a 3D Social Media where people can chat and socialise, while playing minigames such as shooters, or racing around a race track.
                            <p></p>
                            With the last update (Open Alpha v4), The Race Track was added, and that is the first ever minigame that I added. I am really happy
                            with how it turned out, but I am finding it difficult to find new things to add.
                        </a>
                        <a class="news-content-title">Will GTR_Server ever stop being developed?</a>
                        <a class="news-content-text">
                            Genuinely, I don't know. If development does stop, it will be for a good reason (for example, if I am working on a new, much better project).
                            I don't think that development will ever fully end, but the delays between updates might be extremely long of I am busy with different projects.
                        </a>
                        <a class="news-content-title">Have I been working on any new projects instead?</a>
                        <a class="news-content-text">
                            Yes. I have been working on a few projects, all in Unreal Engine.
                            <p></p>
                            The most recent project I worked on (that wasn't GTR_Server) involved me trying to learn how to
                            change the default vehicle mesh in Unreal Engine 5.2 so I can get custom vehicle models.
                            <p></p>
                            The best project that I worked on that isn't GTR_Server is probably a project I won't name, but I made
                            a concept for the first-person-shooter minigame I was going to implement to GTR_Server. I made a 
                            really nice Main-Menu with a nice 3D envornment as the background, and it just looked great (in my opinion).
                            <p></p>
                            I had a lot of issues though, so I stopped working on it a while back, and never implemented (because it didn't work)
                            <p></p>
                        </a>
                        <a class="news-content-title">Will GTR_Server ever be released for mobile? (Android / iOS)</a>
                        <a class="news-content-text">
                            Probably not, and if it ever gets added it won't be avaliable on any App Store (Google Play / Apple App Store).
                            If I am going to be making a mobile version of GTR_Server, it will be for Android, and Android only.
                        </a>
                        <a class=""></a>
                </div>
            </div>
        </body>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js'></script><script src="./script.js"></script>
    </>);
};

export default News1;