/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Routes, Link } from "react-router-dom";
import '../App.css';
import Home from "./home";
import Downloads from "./downloads";
import PatchNotes from "./patch_notes_v4.jsx";
import News1 from "./news-articles/news_1.jsx";

import down_arrow from'../images/down_arrow.png';
import '../components/patch_notes-v4.css';

import './news.css';

const News = () => {
    return (
    <>
    <body class="page-top">
        <header class="header">

            <a class="logo" href="">GTR_Server</a>
            <input class="menu-btn" type="checkbox" id="menu-btn" />
            <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
                <ul class="menu">
                    <nav>
                        <li><Link class="link link-theme link-arrow" to="/">Home</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/downloads">Downloads</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/patch_Notes">Patch Notes</Link></li>
                        <li><Link class="link link-theme link-arrow" to="/news">News</Link></li>
                        <li><a href="about.html" class="link link-theme link-arrow" id="mobile-center-title-second">Info / About</a></li>
                        <li><a href="" class="link link-theme link-arrow" id="mobile-center-title">Report a Bug in GTR_Server</a></li>
                    </nav>
                </ul>
            </header>

            <div>
                <div id="main" class="main">
                    <section class="intro">
                        <article class="container2" id="patch_notes_container_title">

                            <h1 id="underline">News</h1>
                            <h1>
                                GTR_Server <strong> - Patch Notes</strong>
                            </h1>
                            <div id="button_div_external">
                                <div id="button_div">
                                </div>
                            </div>
                            <h2 id="subtitle-home">
                                Version Release Date: To be determined.
                            </h2>

                        </article>
                    </section>
                    
                </div>

                <div class="news-article-box">
                        <a class="news-box-title">Thanks for the support</a>
                        <a class="news-box-subtitle">I have noticed the support this game has recieved and I wanted to thank you all for your interest in this game.</a>
                        <Link class="news-btn" to="/news1">Read More</Link>
                </div>
                <div class="news-article-box">
                        <a class="news-box-title">wow</a>
                </div>
                <div class="news-article-box">
                        <a class="news-box-title">wow</a>
                </div>
            </div>
        </body>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js'></script><script src="./script.js"></script>
    </>);
};

export default News;